

































































































import { Component, Mixins, PropSync, Watch } from "vue-property-decorator";
import { base_class_pantalla } from "@/shared/classes/base-class-pantallas";
import { agrupacion_importacionesDto } from "@/shared/dtos/agrupacion_importacionesDto";
import agrupacion_importacionesDtoModule from "@/store/modules/agrupacion_importacionesDto-module";
@Component({
  components: {},
})
export default class config_agrupacion_datos extends Mixins(
  base_class_pantalla
) {
  public respu: agrupacion_importacionesDto[] = [];
  public editedItem: agrupacion_importacionesDto = new agrupacion_importacionesDto();
  public defaultItem: agrupacion_importacionesDto = new agrupacion_importacionesDto();
  public dialog: boolean = false;
  public dialogDelete: boolean = false;
  public editedIndex = -1;
  public headers = [
    {
      text: "Nombre",
      value: "nombre",
    },
    {
      text: "Fecha creación",
      value: "datecreated",
    },
    { text: "", value: "actions", sortable: false },
  ];

  created() {
    this.nombre_pantalla = "Agrupaciones de datos";
    this.toolbar_texto_editar = "Editar agrupaciones";
    this.toolbar_texto_crear = "Crear nueva agrupación";
    this.es_edicion = Number.parseInt(this.$route.params.id) === -1;
    agrupacion_importacionesDtoModule.getagrupacion_importacionesDtos();
  }

  public get datasourcesync() {
    return agrupacion_importacionesDtoModule.agrupacion_importacionesDtos;
  }

  public get formTitle() {
    return this.editedIndex === -1
      ? this.toolbar_texto_crear
      : this.toolbar_texto_editar;
  }

  public editItem(item: agrupacion_importacionesDto) {
    this.editedIndex = this.datasourcesync.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }

  public deleteItem(item: agrupacion_importacionesDto) {
    this.editedIndex = this.datasourcesync.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  public deleteItemConfirm() {
    agrupacion_importacionesDtoModule
      .eliminaragrupacion_importacionesDto(
        this.datasourcesync[this.editedIndex]
      )
      .then(() =>
        agrupacion_importacionesDtoModule.getagrupacion_importacionesDtos()
      );
    this.datasourcesync.splice(this.editedIndex, 1);
    this.closeDelete();
  }

  public close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public save() {
    if (this.editedIndex > -1) {
      Object.assign(this.datasourcesync[this.editedIndex], this.editedItem);
      agrupacion_importacionesDtoModule
        .modificaragrupacion_importacionesDto(
          new agrupacion_importacionesDto(this.editedItem)
        )
        .then(() => {
          agrupacion_importacionesDtoModule.getagrupacion_importacionesDtos();
          this.close();
        });
    } else {
      this.editedItem.id = 0;
      this.editedItem.datecreated = new Date();
      this.editedItem = new agrupacion_importacionesDto(this.editedItem);
      this.datasourcesync.push(this.editedItem);
      agrupacion_importacionesDtoModule
        .guardaragrupacion_importacionesDto(this.editedItem)
        .then(() => {
          agrupacion_importacionesDtoModule.getagrupacion_importacionesDtos();
          this.close();
        });
    }
  }
}
